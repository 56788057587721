<template>
  <vue-final-modal
    class="modal-self-exclusion-limit"
    :overlay-transition="{ mode: 'in-out', duration: 250 }"
    :content-transition="{ mode: 'in-out', duration: 250 }"
  >
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'updateTitle') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('self-exclusion-limit')" />
      </div>
    </div>

    <div class="vfm__body">
      <div class="modal-self-exclusion-limit__text">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'updateDescription') }}
      </div>

      <div class="modal-self-exclusion-limit__actions">
        <button-base type="primary" size="md" @click="save">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'okButtonLabel') }}
        </button-base>
        <button-base type="secondary-1" size="md" @click="cancel">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'cancelButtonLabel') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent } from '~/types';

  const props = defineProps<{
    period?: string;
    currentLocaleData: Maybe<CIModalsContent['selfExclusionLimit']>;
    defaultLocaleData: Maybe<CIModalsContent['selfExclusionLimit']>;
  }>();

  const limitsStore = useLimitsStore();
  const { createLimit } = limitsStore;
  const { closeModal } = useModalStore();
  const { getContent } = useProjectMethods();
  const { logOutUser } = useProfileStore();

  const save = async () => {
    await closeModal('self-exclusion-limit');
    await createLimit({
      definition: 4,
      period: props.period,
    });
    await logOutUser();
  };

  const cancel = () => {
    closeModal('self-exclusion-limit');
  };
</script>

<style src="~/assets/styles/components/modal/self-exclusion-limit.scss" lang="scss" />
